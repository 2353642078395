import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Typography, Collapse, Alert, AlertTitle, Button, Slide, Dialog } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeLoginAccess } from '@utils/ApiAction';
import { changeLanguage } from '@actions';
import { storePaths } from '@utils/Tools';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat } from '@utils/Tools';

import { HiChevronRight } from "react-icons/hi2";
import { FiUser, FiKey, FiLock, FiPhone } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import { MdOutlineNewspaper } from "react-icons/md";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
const MenuListing = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { username, email, mobile, sub_email, rank_id, max_mrank } = useSelector(state => state.user);
    const isMountedRef = useRef(null);
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const [option, setOption] = useState({
        openLang: false,
    })
    const { addAlert, setLoading } = useNotificationLoading();
    const [data, setData] = useState({});
    const [logoutDialog, setLogoutDialog] = React.useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('user/stakes').then(response => {
            if (response.status && isMountedRef.current) {
                setData({ ...data, ...response.data });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        return () => isMountedRef.current = false;
    }, []);

    const navigateMenu = (path) => {
        navigate(path, { state: { from: storePaths(location) } });
        handleLangClose();
    }

    const handleLangChange = () => {
        setOption({ ...option, openLang: !option?.openLang });
    }

    const handleLangClose = () => {
        setOption({ ...option, openLang: false });
    }

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleLangClose();
    };

    const logout = () => {
        removeLoginAccess();
        navigate('/landing', { replace: true });
        handleLangClose();
    };

    const resendEmailUpdateVerification = () => {
        setLoading(true);
        getUrl(`/user/send-email-verification-url/update`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    //Logout Dialog


    const openLogoutDialog = () => {
        setLogoutDialog(true);
    }
    const closeLogoutDialog = () => {
        setLogoutDialog(false);
    }

    

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            <div className='p-t-20'>
                <div className='glass-box-hightlight' style={{ width: 320, maxWidth: '90%', margin: '0 auto' }}>
                    <div style={{ background: '#16114e', borderRadius: 10 }}>
                        <div className='glass-box txt-white flex-sb-m p-b-40 p-t-15'>
                            <div style={{ textShadow: '1px 1px 1px #0000003b', width:'calc(100% - 70px)' }}>

                                <p className='fs-20 inter-bold p-b-5' style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}><b>{username}</b></p>
                                <div className='inter-normal fs-11 p-b-5'>
                                    <p className='p-b-3'>{email}</p>
                                    <p>{mobile}</p>
                                </div>
                            </div>
                            { max_mrank?.code>0 &&
                                <div className='flex-c-m' style={{width:70}}>
                                    <img src={`/images/rank/${max_mrank?.code}.png`} alt='rank' style={{ width: 70, filter: ' drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
            <div className="gradient-theme wallet-menu shadow-menu-theme" style={{ zIndex: 2, width: 390, margin: '-47px auto 0', maxWidth: '100%', padding:'10px 15px'}}>
                <div className='clwhite txt-center' style={{width:'calc(50% - 1.5px)'}}>
                    <p className='inter-bold'>{currencyFormat(parseFloat(data?.sales?.personal_sales || 0),2)}</p>
                    <p className='txt-upper fs-11 p-t-5'>{t('stake.individual')}</p>
                </div>
                <div style={{height:30, width:3, background: 'rgba(255, 255, 255, 0.32)', boxShadow: '1.5px 0px 0.5px 0px #4B76FF inset'}} />
                <div className='clwhite txt-center' style={{width:'calc(50% - 1.5px)'}}>
                    <p className='inter-bold'>{currencyFormat(parseFloat(data?.sales?.group_sales || 0), 2)}</p>
                    <p className='txt-upper fs-11 p-t-5'>{t('stake.group')}</p>
                </div>
            </div>
            <div className="dis-flex flex-col" style={{ gap: 19 }}>
                {/* {
                    sub_email ?
                        <div>
                            <Alert severity="info">
                                <AlertTitle style={{ color: 'black', fontWeight: 'bold' }}>{t('general.info')}</AlertTitle>
                                <p className='inter-bold fs-13 txt-upper p-b-10'> {t('user.subEmail')}</p>
                                <p className='fs-13 inter-bold'>{sub_email}</p>
                                <p className='inter-normal fs-13 lh-12 p-t-10' >{t('user.verifyEmail')}</p>
                                <Button variant="contained" size="small" onClick={resendEmailUpdateVerification} style={{ marginTop: 15, fontFamily:'Inter' }} >Resend Verification Link</Button>
                            </Alert>
                        </div>
                        : null
                } */}
                
                <div onClick={() => navigateMenu('/setting/profile')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <FiUser className='fs-23 clwhite' />
                        <div className="flex-col">
                           <div className='flex-m'>
                           <Typography variant="subtitle2" className='p-r-10'>{t('title.profile')}</Typography>
                            {
                                sub_email &&
                            <div style={{height:8, width:8, borderRadius:48, background:'#f45353'}}></div>
                            }
                           </div>
                            <p className='cl-label inter-normal fs-13'>{t('setting.profileDesc')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/crypto-address')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <IoWalletOutline className='fs-23 clwhite' />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.cryptoAddress')}</Typography>
                            <p className='cl-label inter-normal fs-13'>{t('setting.cryptoAddressDesc')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/password')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <FiKey className='fs-23 clwhite' />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.password')}</Typography>
                            <p className='cl-label inter-normal fs-13'>{t('setting.passwordDesc')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/security-password')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <FiLock className='fs-23 clwhite' />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.securityPassword')}</Typography>
                            <p className='cl-label inter-normal fs-13'>{t('setting.securityPasswordDesc')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/notices')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <MdOutlineNewspaper className='fs-23 clwhite' />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.newsAnnouncements')}</Typography>
                            <p className='cl-label inter-normal fs-13'>{t('title.newsAnnouncements')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/contact-us')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <FiPhone className='fs-23 clwhite' />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.contactUs')}</Typography>
                            <p className='cl-label inter-normal fs-13'>{t('title.contactUs')}</p>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                {/* <div>
                    <div onClick={handleLangChange} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                        <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                            <IoLanguage style={{ color: 'white', width: 30, height: 30 }} />
                            <div className="flex-col">
                                <Typography variant="subtitle2">{t('general.language')}</Typography>
                                <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.languageDesc')}</Typography>
                            </div>
                        </div>
                        {option?.openLang ? <HiChevronDown className="img-style" style={{ color: 'white' }} /> : <HiChevronRight className="img-style" style={{ color: 'white' }} />}
                    </div>
                    <Collapse in={option?.openLang} timeout="auto" unmountOnExit style={{ paddingLeft: 45 }}>
                        <div className='flex-c flex-col m-b-20'>
                            <Typography onClick={() => changeSystemLanguage('en')} variant="subtitle2" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.en')}</Typography>
                            <Typography onClick={() => changeSystemLanguage('cn')} variant="subtitle2" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.cn')}</Typography>
                        </div>
                    </Collapse>
                </div> */}
                    <Button onClick={openLogoutDialog} variant="outlined" style={{ marginTop: 20 }} fullWidth>
                    {/* onClick={logout} */}
                        <p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.logout')}</p>
                    </Button>
            </div>

            <Dialog 
            open={logoutDialog}
            onClose={closeLogoutDialog}
            TransitionComponent={Transition}
            >
                <div className='p-all-20 w-full dialog-basic'>                
                    <div className='txt-center'>
                        <p className='txt-upper fs-21 inter-bold p-b-10'>{t('button.logout')}</p>
                        <p>{t('general.logoutDescription')}</p>
                    </div>
                    <div className='w-full flex-sb-m p-t-30'>
                    <div className='translateY' style={{width:'calc(50% - 5px)'}}>
                        <div className='button-large-outlined' onClick={closeLogoutDialog}>
                        <p>{t('button.cancel')}</p>
                        </div>
                    </div>
                    <div className='translateY' style={{width:'calc(50% - 5px)'}}>
                        <div className='button-large' onClick={logout}>
                        <p>{t('button.logout')}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    secondary: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

export default MenuListing;