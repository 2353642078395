import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import _, { isUndefined } from 'lodash';

import { PropTypes } from 'prop-types';

const NoticeItem = props => {
    const { info, popup = false } = props;
    const { t, i18n } = useTranslation();
    const parentRef = useRef();
    const language = _.includes(['cn', 'zhtw'], i18n.language) ? 'cn' : 'en';

    const getLangContent = () => {

        if (info?.content_type === "image") {
            let imageDetail = '/images/login_image.png';
            if (_.size(info?.content_images) > 0) {
                let tempData = _.find(info?.content_images, { 'type': `notice_${language}` });
                if (!isUndefined(tempData)) {
                    imageDetail = tempData?.file_name;
                } else {
                    let defaultData = _.find(info?.content_images, { 'type': `notice_en` });
                    if (defaultData) {
                        imageDetail = defaultData?.file_name;
                    }
                }
            }

            return (
                <div>
                    <img src={imageDetail} alt="Notice" className='s-full ' style={{ objectFit: 'cover' }} />
                </div>
            );

        } else if (info?.content_type === "text") {
            // Accessing the iframe and logging its content
            // const iframe = parentRef.current?.getElementsByTagName('iframe');
            // if (_.size(iframe) > 0) {
            //     _.map(iframe, detail => {
            //         detail.style.width = '100%';
            //         detail.style.height = '240px';
            //     })
            // }

            // if (info?.content[language] && info?.content[language] !== null) {
            //     return (
            //         <div ref={parentRef} className='p-tb-10 txt-content txt-white'>
            //             <div dangerouslySetInnerHTML={{ __html: info?.content[language]
            //                 ?.replace(/<img/g, '<img style="object-fit: contain; width: 100%; height: 100%;"')
            //                 ?.replace(/<iframe/g, '<iframe style="width: 100%;"')
            //             }} />
            //         </div>
            //     )
            // } else {
                return (
                    <div ref={parentRef} className='p-tb-10 txt-content txt-white'>
                        <div dangerouslySetInnerHTML={{ __html: info?.content[language]
                            ?.replace(/<img/g, '<img style="object-fit: contain; width: 100%; height: 100%;"')
                            ?.replace(/<iframe/g, '<iframe style="width: 100%;"')
                        }} />
                    </div>
                )
            // }

        } else {
            return (
                <div>
                    <p className='txt-empty'>{t('general.noData')}</p>
                </div>
            )
        }

    }

    return (
        <div>
            {getLangContent()}
        </div>
    )
}

// DEFINE DEFAULT PROPS TYPE
NoticeItem.propTypes = {
    id: PropTypes.any,
};

// DEFINE DEFAULT PROPS VALUE
NoticeItem.defaultProps = {
    id: {},
};

export default NoticeItem;